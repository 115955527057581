<!--header-->
<div class="md:_x_relative">
  <!-- sticky search input for mobile -->
  <div
    *ngIf="!isVisible"
    [formGroup]="searchForm"
    class="_x_fixed _x_top-0 _x_z-10 _x_flex _x_w-full _x_bg-white _x_p-8 _x_shadow-headerShadow md:_x_hidden">
    <div class="_x_relative _x_flex _x_w-full _x_items-center _x_overflow-hidden md:_x_hidden">
      <input
        #inputMobile
        (focus)="onfocus()"
        (focusout)="focusOutMobile()"
        (keyup.enter)="search(searchForm.get('searchKeyword').value, undefined, $event)"
        [ngClass]="{
          '_x_pl-40': (selectUploadedImageForSearch | async),
          '_x_pl-8': !(selectUploadedImageForSearch | async),
          '_x_pr-[46px]': getSearchInputValue,
          '_x_pl-60': showExpressSearch
        }"
        [placeholder]="showExpressSearch ? 'მოძებნე ექსპრესში' : 'მოძებნე რაც გაგიხარდება'"
        class="_x_h-full _x_w-full _x_overflow-ellipsis _x_rounded-l-8 _x_rounded-r-none _x_border-2 _x_border-r-0 _x_border-purple _x_pr-18 _x_font-regular _x_text-3 _x_text-dark-700 _x_outline-none"
        id="inputMobile"
        name="search"
        formControlName="searchKeyword"
        type="text"
        aria-label="Search input" />
      <div
        *ngIf="selectUploadedImageForSearch | async"
        class="_x_absolute _x_bottom-0 _x_left-0 _x_top-0 _x_m-2 _x_ml-3 _x_flex _x_h-19 _x_max-h-full _x_items-center _x_rounded-4 _x_bg-dark-100 _x_p-2">
        <img
          [alt]="(selectUploadedImageForSearch | async).externalId"
          [src]="(selectUploadedImageForSearch | async).externalUrl"
          class="_x_mr-2 _x_max-h-15 _x_w-15 _x_rounded-8" />
        <i
          (click)="clearUploadedSearchImage()"
          class="_x_icon _x_icon-remove-bin _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"
          aria-label="Clear uploaded image"></i>
      </div>
      <div class="_x_absolute _x_right-[64px]">
        <i
          (click)="clearSearch()"
          *ngIf="searchForm.get('searchKeyword').value"
          class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"
          aria-label="Clear search input"></i>
      </div>
      <!--      <button-->
      <!--        (click)="!showExpressSearch && setImageSearchModalState(true)"-->
      <!--        [disabled]="showExpressSearch"-->
      <!--        aria-label="Open image search modal"-->
      <!--        class="_x_absolute _x_right-20 _x_z-1">-->
      <!--        <i-->
      <!--          class="_x_icon _x_icon-camera _x_mr-10 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_text-7 _x_text-dark-700"></i>-->
      <!--      </button>-->
      <button
        (click)="search(userTypedValue, undefined, $event)"
        aria-label="Search"
        class="_x_z-2 _x_flex _x_h-24 _x_items-center _x_justify-center _x_rounded-r-8 _x_border-2 _x_border-purple _x_bg-purple _x_px-6 _x_font-medium _x_text-white _x_transition _x_duration-300 hover:_x_bg-purple-100 hover:_x_text-purple">
        <i
          class="_x_icon _x_icon-search _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
      </button>
    </div>
    <!-- search drop -->
    <div
      *ngIf="showSuggestions"
      class="_x_shadow _x_absolute _x_left-50prc _x_top-36 _x_z-10 _x_flex _x_w-calc-w-full-32 _x_translate-x-[-50%] _x_flex-col _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-5"
      role="listbox"
      aria-labelledby="inputMobile">
      <a
        (click)="search(userTypedValue, elem, $event)"
        *ngFor="let elem of suggestions"
        class="_x_rounded-4 _x_p-2 _x_font-regular _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_bg-dark-100 hover:_x_text-dark"
        role="option">
        {{ getFirstLettersOfUserInput(elem) }}<span class="_x_font-bold">{{ getLastLettersOfUserInput(elem) }}</span>
      </a>
    </div>
    <!-- search drop /.-->
  </div>

  <!---->

  <header class="_x_relative _x_left-0 _x_z-[50] _x_w-full _x_bg-white">
    <div class="_x_top-0 _x_h-0 _x_w-full md:_x_fixed">
      <div>
        <app-smart-banner *ngIf="smartBannerService.openSmartBanner$ | async"></app-smart-banner>
      </div>
      <ng-container *ngIf="selectRibbonActiveState$ | async; else beforeRibbonLoads">
        <div
          class="_x_relative _x_flex _x_h-24 _x_w-full _x_items-center _x_justify-center _x_bg-purple _x_px-8 _x_py-6 _x_text-center md:_x_h-18"
          role="banner">
          <img
            *ngIf="(selectRibbon$ | async).url"
            [alt]="(selectRibbon$ | async).iconDescription || 'Banner icon'"
            class="_x_align-center _x_relative _x_mr-5 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_justify-center"
            [src]="(selectRibbon$ | async).url" />
          <p
            class="_x_line-clamp-2 _x_font-semibold _x_text-1 _x_text-white md:_x_line-clamp-1 md:_x_font-bold md:_x_text-3">
            {{ (selectRibbon$ | async).bannerDescription }}
          </p>
        </div>
      </ng-container>
      <ng-template #beforeRibbonLoads>
        <div class="_x_min-h-24 sm:_x_min-h-18" aria-hidden="true"></div>
      </ng-template>

      <!-- top head -->
      <div [ngClass]="showCategory ? '_x_shadow-none' : '_x_shadow-headerShadow'">
        <div
          class="_x_flex _x_cursor-pointer _x_items-center _x_border-b-1 _x_border-dark-100 _x_bg-white sm:_x_hidden">
          <div
            (click)="openAddressFormModal()"
            class="_x_z-50 _x_flex _x_w-full _x_items-center _x_bg-white _x_px-8 _x_py-4">
            <i
              class="_x_icon-location _x_mr-4 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-black group-hover:_x_text-black"></i>
            <p
              class="_x_overflow-hidden _x_overflow-ellipsis _x_whitespace-nowrap _x_font-medium _x_text-3 _x_text-dark-400">
              {{ selectedAddress?.address || 'მისამართის დამატება' }}
            </p>
          </div>
          <div
            *ngIf="expressService.showAddressSuggestionPopup"
            [ngClass]="!(smartBannerService.openSmartBanner$ | async) || isFromSafari ? '_x_top-0' : '_x_top-[77px]'"
            class="left-0 _x_fixed _x_z-10 _x_h-screen _x_min-h-full _x_w-screen _x_min-w-full _x_overflow-visible _x_bg-dark-900 sm:_x_hidden sm:_x_px-8">
            <app-popular-banner
              (closeExpressAddressSuggestion)="hideExpressPopup()"
              class="_x_m-auto _x_mt-50 _x_flex _x_w-full _x_max-w-780 _x_justify-center _x_pl-0 _x_pr-8 sm:_x_mt-104 sm:_x_justify-between sm:_x_px-0 md:_x_mt-83"></app-popular-banner>
          </div>
        </div>

        <div
          [ngClass]="{ 'md:_x_border-t-0 md:_x_border-dark-100': showHr }"
          class="_x_flex _x_w-full _x_items-center _x_bg-white md:_x_h-34">
          <div class="_x_m-auto _x_w-full _x_max-w-780 _x_px-8 _x_pb-0">
            <div class="_x_flex _x_justify-between _x_gap-5 _x_pb-7 _x_pt-7 md:_x_p-0">
              <div class="_x_mr-3 _x_flex _x_items-center md:_x_gap-10">
                <!-- mobile burger btn -->
                <button
                  (click)="openCategories()"
                  aria-label="Open Categories"
                  class="_x_mr-10 _x_flex _x_items-center _x_justify-center _x_bg-purple _x_bg-transparent _x_text-dark _x_outline-none hover:_x_text-purple md:_x_hidden">
                  <i
                    [ngClass]="showCategory ? '_x_icon-remove' : '_x_icon-burger'"
                    class="_x_icon _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
                </button>
                <!-- mobile burger btn /.-->

                <a
                  (click)="scrollTop()"
                  class="_x_w-54 _x_outline-none sm:_x_mr-6 md:_x_mr-0 md:_x_min-w-74"
                  routerLink="/"
                  aria-label="go to home page">
                  <img
                    [src]="'../assets/atomic-assets/img/logo/new-logo-black-no-whitespace.svg'"
                    alt="extra.ge logo"
                    class="_x_h-[48px] _x_w-full" />
                </a>
                <!-- logo/. -->

                <!-- address input -->
                <div
                  [ngClass]="{
                    '_x_opacity-1': !disableAddressInput || !hasUserPrivacyPolicyUpdated,
                    '_x_cursor-pointer': disableAddressInput && hasUserPrivacyPolicyUpdated
                  }"
                  class="_x_relative _x_hidden _x_w-100 _x_items-center _x_px-2 sm:_x_flex md:_x_h-24">
                  <div
                    (click)="openAddressFormModal()"
                    [ngClass]="{ '_x_ml-1 _x_border-2': expressService.showAddressSuggestionPopup }"
                    class="_x_z-11 _x_flex _x_w-96 _x_items-center _x_rounded-6 _x_border-purple _x_bg-white">
                    <i
                      class="_x_icon-location _x_mr-3 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-black group-hover:_x_text-black"></i>

                    <p
                      *ngIf="isMobile; else webAddressInput"
                      [ngClass]="selectedAddress?.address ? '_x_text-dark' : '_x_text-dark-400'"
                      class="xl:215 _x_w-full _x_max-w-120 _x_overflow-hidden _x_overflow-ellipsis _x_whitespace-nowrap _x_font-medium _x_text-3 lg:_x_max-w-189">
                      {{ selectedAddress?.address || 'მისამართის დამატება' }}
                    </p>

                    <ng-template #webAddressInput>
                      <div class="_x_flex _x_w-84 _x_flex-col _x_justify-center _x_gap-1">
                        <p *ngIf="selectedAddress?.address" class="_x_font-bold _x_text-3 _x_text-dark">
                          {{ selectedAddress?.location }}
                        </p>
                        <p
                          [ngClass]="
                            selectedAddress?.address ? '_x_text-2 _x_text-dark-700' : '_x_text-3 _x_text-dark-500'
                          "
                          class="xl:215 _x_w-full _x_max-w-120 _x_overflow-hidden _x_overflow-ellipsis _x_whitespace-nowrap _x_font-medium lg:_x_max-w-83">
                          {{
                            (this.selectedAddress?.address | splitAddress: this.selectedAddress?.location) ||
                              'მისამართის დამატება'
                          }}
                        </p>
                      </div>
                    </ng-template>
                  </div>

                  <div
                    *ngIf="expressService.showAddressSuggestionPopup"
                    class="_x_fixed _x_left-0 _x_top-0 _x_z-10 _x_h-screen _x_min-h-full _x_w-screen _x_min-w-full _x_bg-dark-900"></div>
                  <app-popular-banner
                    *ngIf="expressService.showAddressSuggestionPopup"
                    (closeExpressAddressSuggestion)="hideExpressPopup()"
                    class="_x_absolute _x_top-[38px] _x_z-50 _x_m-auto _x_flex _x_w-max _x_max-w-780 _x_justify-between md:_x_top-[48px]"></app-popular-banner>
                </div>
              </div>

              <!-- search -->
              <div [formGroup]="searchForm" class="_x_relative _x_hidden _x_w-362 md:_x_flex">
                <div class="_x_relative _x_hidden _x_w-full _x_items-center _x_overflow-hidden md:_x_flex">
                  <input
                    #inputDesktop
                    (focus)="onfocus()"
                    (focusout)="focusOut()"
                    (keyup)="updateSuggestionsVariable($event)"
                    (keyup.arrowdown)="markSuggestion($event)"
                    (keyup.arrowup)="markSuggestion($event)"
                    (keyup.enter)="search(searchForm.get('searchKeyword')?.value, undefined, $event)"
                    [ngClass]="{
                      '_x_pl-40': (selectUploadedImageForSearch | async),
                      '_x_pl-8': !(selectUploadedImageForSearch | async),
                      '_x_pr-[46px]': getSearchInputValue,
                      'md:_x_pl-67': showExpressSearch
                    }"
                    [placeholder]="searchPlaceholder"
                    class="_x_h-full _x_w-full _x_overflow-ellipsis _x_rounded-l-8 _x_rounded-r-none _x_border-2 _x_border-r-0 _x_border-purple _x_pr-18 _x_font-regular _x_text-3 _x_text-dark-700 _x_outline-none"
                    formControlName="searchKeyword"
                    id="inputDesktop"
                    name="search"
                    type="text" />
                  <div
                    *ngIf="selectUploadedImageForSearch | async"
                    class="_x_absolute _x_left-0 _x_m-3 _x_flex _x_h-19 _x_items-center _x_rounded-4 _x_bg-dark-100 _x_p-2">
                    <img
                      [alt]="(selectUploadedImageForSearch | async).externalId"
                      [src]="(selectUploadedImageForSearch | async).externalUrl"
                      class="_x_mr-2 _x_max-h-15 _x_w-15 _x_rounded-8" />
                    <i
                      (click)="clearUploadedSearchImage()"
                      class="_x_icon _x_icon-remove-bin _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"></i>
                  </div>
                  <!-- search cases -->
                  <div
                    *ngIf="showExpressSearch"
                    class="_x_absolute _x_left-0 _x_m-2 _x_flex _x_h-20 _x_items-center _x_rounded-4 _x_bg-dark-100 _x_px-6 _x_py-5">
                    <span class="_x_mr-4 _x_font-medium _x_text-3 _x_text-black">ექსპრესი</span>
                    <i
                      (click)="removeExpressSearch()"
                      class="_x_icon _x_icon-remove _x_flex _x_h-8 _x_max-h-8 _x_min-h-8 _x_w-8 _x_min-w-8 _x_cursor-pointer _x_items-center _x_justify-center _x_text-4 _x_text-black"></i>
                  </div>
                  <!-- search cases -->
                  <div class="_x_absolute _x_right-[64px]">
                    <i
                      (click)="clearSearch()"
                      *ngIf="searchForm.get('searchKeyword').value"
                      class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"></i>
                  </div>
                  <!--                  <button-->
                  <!--                    (click)="!showExpressSearch && setImageSearchModalState(true)"-->
                  <!--                    [disabled]="showExpressSearch"-->
                  <!--                    aria-label="Justify"-->
                  <!--                    class="_x_absolute _x_right-20 _x_z-1">-->
                  <!--                    <i-->
                  <!--                      class="_x_icon _x_icon-camera _x_mr-12 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_text-7 _x_text-dark-700"></i>-->
                  <!--                  </button>-->
                  <button
                    (click)="search(userTypedValue, undefined, $event)"
                    aria-label="Justify"
                    class="_x_z-2 _x_flex _x_h-24 _x_items-center _x_justify-center _x_rounded-r-8 _x_border-2 _x_border-purple _x_bg-purple _x_px-6 _x_font-medium _x_text-white _x_transition _x_duration-300 hover:_x_bg-purple-100 hover:_x_text-purple">
                    <i
                      class="_x_icon _x_icon-search _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
                  </button>
                </div>
                <!-- search drop -->
                <div
                  *ngIf="showSuggestions"
                  class="_x_shadow _x_absolute _x_left-0 _x_top-27 _x_z-12 _x_hidden _x_w-full _x_flex-col _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-5 md:_x_flex">
                  <a
                    (click)="search(userTypedValue, elem, $event)"
                    *ngFor="let elem of suggestions; let i = index"
                    [ngClass]="i === suggestionsKeyControlIndex ? '_x_bg-dark-100' : ''"
                    class="_x_rounded-4 _x_px-5 _x_py-3 _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_bg-dark-100 hover:_x_text-dark">
                    {{ getFirstLettersOfUserInput(elem)
                    }}<span class="_x_font-semibold">{{ getLastLettersOfUserInput(elem) }}</span>
                  </a>
                </div>
                <!-- search drop /.-->
              </div>
              <!-- search /.-->

              <!--  right bar -->
              <div
                class="_x_relative _x_flex _x_w-full _x_items-center _x_justify-between xs:_x_w-auto lg:_x_pl-10 xl:_x_pl-15">
                <a
                  aria-label="visit comparison page"
                  class="_x_flex _x_flex-col _x_items-center _x_justify-end _x_text-dark hover:_x_text-purple"
                  routerLink="/comparison">
                  <i
                    class="_x_icon _x_icon-Compare _x_relative _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7">
                    <div
                      *ngIf="getComparisonCount$ | async"
                      class="_x_absolute _x_left-8 _x_top-minus-5 _x_rounded-10 _x_bg-white _x_p-1">
                      <div class="_x_rounded-10 _x_bg-red _x_px-2 _x_py-1">
                        <p
                          class="_x_flex _x_h-7 _x_min-w-4 _x_items-center _x_justify-center _x_font-bold _x_text-2 _x_text-white">
                          {{ getComparisonCount$ | async }}
                        </p>
                      </div>
                    </div>
                  </i>

                  <span class="_x_text _x_font-light _x_hidden _x_font-medium _x_text-2 _x_text-dark-700 md:_x_flex"
                    >შედარება</span
                  >
                </a>
                <a
                  aria-label="visit wishlist page"
                  class="wishlist-icon _x_flex _x_flex-col _x_items-center _x_justify-end _x_text-dark hover:_x_text-purple xs:_x_mx-12 md:_x_mx-16"
                  routerLink="/wishlist">
                  <div
                    class="_x_icon _x_relative _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        class="wishlist-path"
                        d="M13.65 7.95002C14.25 6.65002 15.55 5.75002 17.15 5.75002C19.25 5.75002 21.05 7.45002 21.05 9.65002C21.05 10.95 20.35 12.15 19.45 12.85L12.1314 18.217C12.0239 18.2958 11.8772 18.2942 11.7714 18.2131L4.64995 12.75C3.64995 12.05 2.94995 10.95 2.94995 9.55002C2.94995 7.45002 4.64995 5.65002 6.84995 5.65002C8.34995 5.65002 9.64995 6.55002 10.35 7.85002L11.6939 10.5379C11.8021 10.7544 12.1089 10.7602 12.2253 10.548L13.65 7.95002Z"
                        stroke-width="1.6"
                        stroke-miterlimit="10" />
                    </svg>
                    <div
                      *ngIf="getWishlistProductsCount$ | async"
                      class="_x_absolute _x_left-5 _x_top-minus-5 _x_rounded-10 _x_bg-white _x_p-1">
                      <div class="_x_rounded-10 _x_bg-red _x_px-2 _x_py-1">
                        <p
                          class="_x_flex _x_h-7 _x_min-w-4 _x_items-center _x_justify-center _x_font-bold _x_text-2 _x_text-white">
                          {{ getWishlistProductsCount$ | async }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <span class="_x_text _x_font-light _x_hidden _x_font-medium _x_text-2 _x_text-dark-700 md:_x_flex"
                    >რჩეულები</span
                  >
                </a>
                <a
                  aria-label="visit basket page"
                  class="_x_flex _x_flex-col _x_items-center _x_justify-end _x_text-dark hover:_x_text-purple xs:_x_mr-12 md:_x_mr-16"
                  routerLink="/basket">
                  <i
                    class="_x_icon _x_icon-cart-bucket _x_relative _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7">
                    <div
                      *ngIf="getBasketProductCount$ | async"
                      class="_x_absolute _x_left-5 _x_top-minus-5 _x_rounded-10 _x_bg-white _x_p-1">
                      <div class="_x_rounded-10 _x_bg-red _x_px-2 _x_py-1">
                        <p
                          class="_x_flex _x_h-7 _x_min-w-4 _x_items-center _x_justify-center _x_font-bold _x_text-2 _x_text-white">
                          {{ getBasketProductCount$ | async }}
                        </p>
                      </div>
                    </div>
                  </i>

                  <span class="_x_text _x_font-light _x_hidden _x_font-medium _x_text-2 _x_text-dark-700 md:_x_flex"
                    >კალათა</span
                  >
                </a>
                <button
                  (click)="toSignIn()"
                  *ngIf="!(userStatus$ | async)"
                  aria-label="login"
                  class="_x_flex _x_h-15 _x_w-auto _x_items-center _x_justify-center _x_rounded-8 _x_font-medium _x_text-dark _x_transition _x_duration-300 hover:_x_border-dark md:_x_mr-0 md:_x_h-24 md:_x_w-57 md:_x_border md:_x_border-dark-200 md:_x_px-8">
                  <span class="_x_mr-1 _x_hidden _x_font-bold _x_text-3 md:_x_block">შესვლა</span>
                  <i
                    class="_x_icon _x_icon-user-profile-1 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
                </button>

                <!-- mobile dropdown -->
                <div
                  *ngIf="(userStatus$ | async) && (user$ | async) as user"
                  appDropDown
                  class="_x_flex _x_h-full _x_items-center _x_justify-center md:_x_hidden">
                  <div class="_x_flex _x_cursor-pointer _x_items-center _x_outline-none">
                    <span class="dropper">
                      <figure
                        *ngIf="user.avatarUrl; else defIcon"
                        class="_x_mb-0 _x_ml-5 _x_flex _x_h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-full xs:_x_ml-0 md:_x_h-24 md:_x_w-24 md:_x_min-w-24">
                        <img [src]="user.avatarUrl" alt="avatar icon" class="_x_max-w-full _x_max-h-full _x_w-full" />
                      </figure>
                      <ng-template #defIcon>
                        <i
                          class="_x_icon _x_icon-user-profile-1 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_rounded-full _x_bg-dark-100 _x_text-7 _x_text-white"></i>
                      </ng-template>
                    </span>
                    <!-- mobile user info and links list -->
                    <div
                      class="drop _x_fixed _x_left-0 _x_top-0 _x_z-51 _x_h-screen _x_w-full _x_overflow-y-auto _x_bg-white _x_px-10 _x_pb-10 _x_pt-4">
                      <div class="_x_flex _x_justify-end">
                        <button
                          class="_x_icon _x_icon-remove _x_flex _x_h-10 _x_max-h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_cursor-pointer _x_items-center _x_justify-center _x_p-10 _x_text-4 _x_text-black"></button>
                      </div>
                      <div class="_x_flex _x_flex-col _x_items-center">
                        <!--User Profile photo-->
                        <figure
                          class="_x_ml-none _x_mr-none _x_mt-none _x_mb-8 _x_flex _x_h-40 _x_min-h-40 _x_w-40 _x_min-w-40 _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-full _x_border-2 _x_border-white _x_bg-dark-100 _x_shadow-profileAvatarShadowOnMobile">
                          <label class="_x_m-0" for="imageUpload">
                            <img
                              *ngIf="user.avatarUrl; else noAvatar"
                              [src]="user.avatarUrl"
                              alt="avatar icon"
                              class="_x_max-w-full _x_w-full _x_cursor-pointer" />
                            <ng-template #noAvatar>
                              <i
                                class="_x_items-center_x_text-white _x_icon-user-profile-1 _x_flex _x_text-icon-16"></i>
                            </ng-template>
                          </label>
                        </figure>
                        <!-- auth mobile dropdown list -->
                        <ng-template #defIcon>
                          <i
                            class="_x_icon _x_icon-user-profile-1 _x_flex _x_h-24 _x_min-h-24 _x_w-24 _x_min-w-24 _x_items-center _x_justify-center _x_rounded-full _x_bg-dark-100 _x_text-10 _x_text-white"></i>
                        </ng-template>
                        <!-- auth mobile dropdown list /.-->

                        <div class="_x_overflow-hidden _x_text-center">
                          <!--User Name-->
                          <div class="_x_mb-4 _x_flex _x_items-center">
                            <h4 class="_x_font-medium _x_text-3 _x_text-dark">
                              <span class="_x_overflow-hidden _x_overflow-ellipsis _x_whitespace-nowrap"
                                >{{ user.firstName }} {{ user.lastName }}</span
                              >
                            </h4>
                            <i
                              *ngIf="userVerifiedStatus$ | async"
                              class="_x_icon-done-check _x_ml-4 _x_flex _x_h-8 _x_w-8 _x_items-center _x_justify-center _x_rounded-full _x_bg-green _x_text-4 _x_text-white"></i>
                          </div>
                          <!---->
                          <div class="_x_font-medium _x_text-2 _x_text-dark-600">
                            <!--user ID-->
                            <span class="">ID - {{ user.userExternalId }}</span>
                            <!--user rate-->
                          </div>
                        </div>
                      </div>
                      <!-- balance area -->
                      <div
                        class="_x_flex _x_items-center _x_justify-center _x_border-b _x_border-dark-200 _x_pb-12 _x_pt-8">
                        <button
                          [routerLink]="'/user/profile/balance'"
                          aria-label="visit profile balance page"
                          class="_x_mr-2 _x_flex _x_h-24 _x_items-center _x_justify-center _x_rounded-40 _x_border _x_border-dark-300 _x_px-8 _x_font-medium _x_text-dark _x_transition _x_duration-300 hover:_x_border-dark">
                          <span class="_x_mr-4 _x_font-medium _x_text-2 _x_text-black">{{
                            'TEXT.BALANCE' | translate
                          }}</span>
                          <span class="_x_font-bold _x_text-4 _x_text-dark">{{ user.balance }}</span>
                          <i
                            class="_x_icon-gel _x_icon _x_flex _x_h-8 _x_w-8 _x_min-w-8 _x_items-center _x_justify-center _x_pl-1 _x_text-5 _x_text-dark"></i>
                          <i
                            class="_x_icon-plus _x_icon _x_ml-4 _x_flex _x_h-12 _x_min-h-10 _x_w-10 _x_min-w-12 _x_items-center _x_justify-center _x_text-7 _x_text-dark"></i>
                        </button>
                      </div>
                      <!-- balance area /. -->

                      <!-- account atom dropdown layer -->

                      <ul class="_x_overflow-hidden _x_pt-5">
                        <li class="">
                          <a
                            aria-label="visit profile orders page"
                            [routerLink]="'/user/profile/orders'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-orders _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >ჩემი შეკვეთები</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile addresses page"
                            [routerLink]="'/user/profile/addresses'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-location _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>

                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >მისამართები</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile data page"
                            [routerLink]="'/user/profile/data'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-user-profile-1 _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark">
                              პროფილი</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile my-cards page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/my-cards'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-card _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >ბარათები
                            </span>
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile voucher-redemption page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/voucher-redemption'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-gift-card-2 _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >ვაუჩერის განაღდება
                            </span>
                          </a>
                        </li>
                        <li *ngIf="!isUserLegalEntity" class="">
                          <a
                            aria-label="visit profile invite-friends page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/invite-friends'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pr-5">
                            <i
                              class="_x_icon-ph_megaphone-bold _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >მოიწვიე მეგობრები
                            </span>
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile balance page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/balance'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-wallet _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >ბალანსის შევსება</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile security page"
                            [routerLink]="'/user/profile/security'"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-security _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >პაროლის შეცვლა</span
                            >
                          </a>
                        </li>
                        <li class="_x_mb-45 sm:_x_mb-0">
                          <a
                            aria-label="logout go to home page"
                            (click)="logout()"
                            class="_x_text-decoration-none hover:_x_font-8 _x_group _x_flex _x_items-center _x_py-5">
                            <i
                              class="_x_icon-logout _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-dark"></i>
                            <span
                              class="_x_m-0 _x_font-medium _x_text-4 _x_text-dark-700 group-hover:_x_font-bold group-hover:_x_text-dark"
                              >გასვლა</span
                            >
                          </a>
                        </li>
                      </ul>

                      <!-- account atom dropdown layer /.-->
                    </div>
                    <!-- mobile user info and links list -->
                  </div>
                  <!-- auth mobile dropdown list /.-->
                </div>
                <!-- mobile dropdown end -->

                <!-- desktop dropdown -->
                <div
                  [isEnabled]="isProfileDropdownOpen"
                  clickOutside
                  (onClickOutside)="clickedOutside()"
                  *ngIf="(userStatus$ | async) && (user$ | async)"
                  class="_x_relative _x_hidden _x_h-full _x_cursor-pointer _x_items-center _x_justify-center md:_x_flex">
                  <!--user login-->
                  <div class="_x_flex _x_h-full _x_items-center _x_justify-center" (click)="toggleProfileDropdown()">
                    <div id="userData"></div>

                    <!-- useer info -->
                    <span class="_x_flex _x_items-center _x_outline-none">
                      <ng-container *ngIf="(userStatus$ | async) && (user$ | async) as user">
                        <figure
                          *ngIf="user.avatarUrl; else defIcon"
                          class="_x_mb-0 _x_ml-5 _x_flex _x_h-15 _x_w-15 _x_min-w-15 _x_items-center _x_justify-center _x_overflow-hidden _x_rounded-full xs:_x_ml-0 md:_x_h-24 md:_x_w-24 md:_x_min-w-24">
                          <img [src]="user.avatarUrl" alt="avatar icon" class="_x_max-w-full _x_max-h-full _x_w-full" />
                        </figure>
                        <ng-template #defIcon>
                          <i
                            class="_x_icon _x_icon-user-profile-1 _x_flex _x_h-24 _x_min-h-24 _x_w-24 _x_min-w-24 _x_items-center _x_justify-center _x_rounded-full _x_bg-dark-100 _x_text-10 _x_text-white"></i>
                        </ng-template>
                      </ng-container>
                    </span>
                    <!-- useer info /-->

                    <!-- account atom dropdown layer -->
                    <div
                      *ngIf="(user$ | async) && isProfileDropdownOpen"
                      class="_x_absolute _x_right-0 _x_top-full _x_z-2 _x_pt-5 _x_transition _x_duration-300 lg:_x_min-w-113">
                      <ul
                        class="_x_shadow-dark100 _x_overflow-hidden _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_pb-7 _x_pl-5 _x_pr-5 _x_pt-7 _x_shadow-default">
                        <li class="">
                          <a
                            aria-label="visit profile orders page"
                            [routerLink]="'/user/profile/orders'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-orders _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >ჩემი შეკვეთები</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile addresses page"
                            [routerLink]="'/user/profile/addresses'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-location _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >მისამართები</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile data page"
                            [routerLink]="'/user/profile/data'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-user-profile-1 _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark">
                              პროფილი</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile my-cards page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/my-cards'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-card _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >ბარათები
                            </span>
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile balance page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/balance'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-wallet _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >ბალანსის შევსება</span
                            >
                          </a>
                        </li>
                        <li *ngIf="!isUserLegalEntity" class="">
                          <a
                            aria-label="visit profile invite-friends page"
                            (click)="saveReferrer()"
                            [routerLink]="'/user/profile/invite-friends'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-ph_megaphone-bold _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >მოიწვიე მეგობრები
                            </span>
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile voucher-redemption page"
                            [routerLink]="'/user/profile/voucher-redemption'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-gift-card-2 _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >ვაუჩერის განაღდება</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="visit profile security page"
                            [routerLink]="'/user/profile/security'"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-security _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-black"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >პაროლის შეცვლა</span
                            >
                          </a>
                        </li>
                        <li class="">
                          <a
                            aria-label="logout go to home page"
                            (click)="logout()"
                            class="_x_group _x_flex _x_items-center _x_rounded-8 _x_pl-5 _x_pr-5 hover:_x_bg-dark-100">
                            <i
                              class="_x_icon-logout _x_mr-5 _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_text-8 _x_text-dark-700 group-hover:_x_text-dark"></i>
                            <span
                              class="_x_text _x_h-17 _x_whitespace-nowrap _x_pt-2 _x_font-medium _x_text-4 _x_text-dark"
                              >გასვლა</span
                            >
                          </a>
                        </li>
                      </ul>
                    </div>
                    <!-- account atom dropdown layer /.-->
                  </div>
                </div>
                <!-- desktop dropdown end-->
              </div>
              <!--  right bar /.-->
            </div>
            <!-- search mobile -->
            <div *ngIf="isVisible" [formGroup]="searchForm" class="_x_relative _x_flex _x_w-full md:_x_hidden">
              <div class="_x_relative _x_flex _x_w-full _x_items-center _x_overflow-hidden md:_x_hidden">
                <input
                  #inputMobile
                  (focus)="onfocus()"
                  (focusout)="focusOutMobile()"
                  (keyup.enter)="search(searchForm.get('searchKeyword').value, undefined, $event)"
                  [ngClass]="{
                    '_x_pl-40': (selectUploadedImageForSearch | async),
                    '_x_pl-8': !(selectUploadedImageForSearch | async),
                    '_x_pr-[46px]': getSearchInputValue,
                    '_x_pl-60': showExpressSearch
                  }"
                  [placeholder]="showExpressSearch ? 'მოძებნე ექსპრესში' : 'მოძებნე რაც გაგიხარდება'"
                  class="_x_h-full _x_w-full _x_overflow-ellipsis _x_rounded-l-8 _x_rounded-r-none _x_border-2 _x_border-r-0 _x_border-purple _x_pr-18 _x_font-regular _x_text-3 _x_text-dark-700 _x_outline-none"
                  id="inputMobile"
                  name="search"
                  formControlName="searchKeyword"
                  type="text" />
                <div
                  *ngIf="selectUploadedImageForSearch | async"
                  class="_x_absolute _x_bottom-0 _x_left-0 _x_top-0 _x_m-2 _x_ml-3 _x_flex _x_h-19 _x_max-h-full _x_items-center _x_rounded-4 _x_bg-dark-100 _x_p-2">
                  <img
                    [alt]="(selectUploadedImageForSearch | async).externalId"
                    [src]="(selectUploadedImageForSearch | async).externalUrl"
                    class="_x_mr-2 _x_max-h-15 _x_w-15 _x_rounded-8" />
                  <i
                    (click)="clearUploadedSearchImage()"
                    class="_x_icon _x_icon-remove-bin _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"></i>
                </div>
                <div class="_x_absolute _x_right-[64px]">
                  <i
                    (click)="clearSearch()"
                    *ngIf="searchForm.get('searchKeyword').value"
                    class="_x_icon _x_icon-remove _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_justify-center _x_text-6 _x_text-dark-500"></i>
                </div>
                <!--                <button-->
                <!--                  (click)="!showExpressSearch && setImageSearchModalState(true)"-->
                <!--                  [disabled]="showExpressSearch"-->
                <!--                  aria-label="search button"-->
                <!--                  class="_x_absolute _x_right-20 _x_z-1">-->
                <!--                  <i-->
                <!--                    class="_x_icon _x_icon-camera _x_mr-10 _x_flex _x_h-12 _x_max-h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_cursor-pointer _x_items-center _x_text-7 _x_text-dark-700"></i>-->
                <!--                </button>-->
                <button
                  (click)="search(userTypedValue, undefined, $event)"
                  aria-label="search button"
                  class="_x_z-2 _x_flex _x_h-24 _x_items-center _x_justify-center _x_rounded-r-8 _x_border-2 _x_border-purple _x_bg-purple _x_px-6 _x_font-medium _x_text-white _x_transition _x_duration-300 hover:_x_bg-purple-100 hover:_x_text-purple">
                  <i
                    class="_x_icon _x_icon-search _x_flex _x_h-12 _x_min-h-12 _x_w-12 _x_min-w-12 _x_items-center _x_justify-center _x_text-7"></i>
                </button>
              </div>
              <!-- search drop -->
              <div
                *ngIf="showSuggestions"
                class="_x_shadow _x_absolute _x_left-0 _x_top-27 _x_z-10 _x_flex _x_w-full _x_flex-col _x_rounded-12 _x_border _x_border-dark-100 _x_bg-white _x_p-5">
                <a
                  aria-label="searched items, link"
                  (click)="search(userTypedValue, elem, $event)"
                  *ngFor="let elem of suggestions"
                  class="_x_rounded-4 _x_p-2 _x_font-regular _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_bg-dark-100 hover:_x_text-dark">
                  {{ getFirstLettersOfUserInput(elem)
                  }}<span class="_x_font-bold">{{ getLastLettersOfUserInput(elem) }}</span>
                </a>
              </div>
              <!-- search drop /.-->
            </div>
            <!-- search mobile /.-->
          </div>
          <div *ngIf="showCategory" [ngStyle]="{ width: scrollbarWidth }"></div>
        </div>
        <!-- top head /. -->

        <!-- sub head -->
        <section
          *ngIf="showSecondLevelHeader"
          class="_x_min-h-26 _x_flex _x_w-full _x_items-center _x_bg-white _x_px-8">
          <nav
            class="category-custom-scroll-bar _x_m-auto _x_flex _x_w-full _x_max-w-765 _x_justify-start _x_gap-6 _x_overflow-x-auto sm:_x_scrollbar-hide">
            <!-- catalog -->
            <button
              (click)="openCategories(true)"
              [ngClass]="{ '_x_bg-purple _x_pl-5 _x_text-white hover:_x_text-white': showCategory }"
              aria-label="open categories"
              class="_x_hidden _x_h-20 _x_items-center _x_justify-center _x_rounded-8 _x_pr-5 _x_font-medium _x_text-darkBlue _x_transition _x_duration-300 hover:_x_text-purple md:_x_flex"
              id="catalogBtn">
              <i
                [ngClass]="showCategory ? '_x_icon-remove' : '_x_icon-burger'"
                class="_x_icon _x_flex _x_h-10 _x_min-h-10 _x_w-10 _x_min-w-10 _x_items-center _x_justify-center _x_text-6"></i>
              <span class="_x_ml-4 _x_font-bold _x_text-3">კატალოგი</span>
            </button>

            <div
              [ngClass]="{
                '_x_scrollbar-w-10 _x_scrollbar-thumb-rounded-8 _x_flex _x_items-center _x_overflow-x-auto _x_scrollbar-thumb-dark-200 _x_scrollbar-track-transparent':
                  hidePopInfo,
                _x_invisible: showCategory
              }"
              class="_x_flex _x_w-full _x_items-center _x_justify-between _x_pb-[11px] _x_pt-5 md:_x_py-0">
              <ul
                class="_x_flex _x_h-full _x_w-full _x_items-center _x_justify-start _x_gap-5 sm:_x_gap-7 md:_x_justify-between xl:_x_justify-start">
                <li class="_x_pr-[5px]">
                  <app-express-button class="_x_flex"></app-express-button>
                </li>

                <li>
                  <a
                    aria-label="visit discount page"
                    [queryParams]="{ filterByDiscount: true }"
                    class="_x_inline-block _x_cursor-pointer _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/discount">
                    ფასდაკლებები
                  </a>
                </li>

                <a
                  aria-label="visit express page"
                  (click)="setExpressSearchCase()"
                  class="_x_invisible _x_absolute _x_inline-block _x_w-0 _x_cursor-pointer _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                  id="expressBtnPlain"
                  routerLink="/express"
                  >ექსპრესი</a
                >

                <li>
                  <a
                    aria-label="visit voucher page"
                    class="_x_inline-block _x_cursor-pointer _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/voucher"
                    >ვაუჩერი</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit shops page"
                    class="_x_inline-block _x_cursor-pointer _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/sellers"
                    >მაღაზიები</a
                  >
                </li>

                <li class="_x_align-center _x_relative _x_flex _x_justify-center">
                  <a
                    aria-label="visit popular page"
                    [routerLink]="['/', 'catalog', 'set', 'popular', popularProductsId]"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    >პოპულარული
                  </a>
                </li>

                <li>
                  <a
                    aria-label="visit toys page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/catalog/satamashoebi/1386"
                    >სათამაშოები</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit technik page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple"
                    routerLink="/catalog/teqnika/1"
                    >ტექნიკა</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit decor page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple sm:_x_hidden md:_x_block"
                    routerLink="/catalog/sakhli-dekori-aveji/8"
                    >სახლი დეკორი ავეჯი</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit sport and travel page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple sm:_x_hidden rg:_x_block"
                    routerLink="/catalog/sporti-da-mogzauroba/918"
                    >სპორტი და მოგზაურობა</a
                  >
                </li>

                <li>
                  <a
                    aria-label="visit Beauty and self care page"
                    class="_x_inline-block _x_whitespace-nowrap _x_p-[5px] _x_font-medium _x_text-3 _x_text-dark _x_transition _x_duration-300 hover:_x_text-purple sm:_x_hidden xl:_x_block"
                    routerLink="/catalog/silamaze-da-tavis-movla/176"
                    >სილამაზე და თავის მოვლა</a
                  >
                </li>
              </ul>
            </div>
          </nav>
        </section>
      </div>
      <div class="_x_top-0 _x_w-full _x_bg-white">
        <ng-template appDynamicHost></ng-template>
      </div>
    </div>
  </header>
</div>

<!--header /.-->

<ng-container *ngIf="imageSearchModalState | async">
  <div class="_x_fixed _x_z-10 _x_h-screen _x_w-full"></div>
  <div
    class="_x_fixed _x_top-0 _x_z-10 _x_m-auto _x_flex _x_h-screen _x_w-full _x_items-center _x_justify-center _x_bg-dark-900 _x_p-10">
    <div
      class="_x_relative _x_bottom-0 _x_left-0 _x_right-0 _x_top-0 _x_z-10 _x_m-auto _x_my-auto _x_flex _x_max-h-174 _x_max-w-243 _x_select-none _x_items-center _x_justify-center _x_rounded-8 _x_bg-white md:_x_mx-auto">
      <div
        class="_x_relative _x_mx-10 _x_mb-15 _x_mt-30 _x_flex _x_h-full _x_w-full _x_flex-col _x_items-center _x_justify-center _x_rounded-8 _x_border-2 _x_border-dashed _x_border-purple _x_px-22 _x_py-15 md:_x_mx-25 md:_x_my-25 md:_x_py-20"
        imageForSearch>
        <input
          (change)="searchByImage($event)"
          accept="image/jpg, image/jpeg, image/png"
          class="_x_absolute _x_top-0 _x_z-10 _x_hidden _x_h-full _x_w-full _x_opacity-0"
          id="uploadImage"
          type="file" />
        <button
          aria-label="add similar product image"
          class="_x_flex _x_items-center _x_justify-center _x_rounded-full _x_bg-purple _x_p-7">
          <i class="_x_icon _x_icon-camera _x_cursor-pointer _x_text-7 _x_text-white"></i>
        </button>
        <p class="_x_my-10 _x_text-center _x_font-medium _x_text-3 _x_text-dark">
          ატვირთე ფოტო მსგავსი პროდუქციის სანახავად
        </p>
        <label
          *ngIf="!(selectImageForSearchIsUploading | async); else loader"
          class="_x_group _x_mb-4 _x_flex _x_h-21 _x_w-full _x_max-w-78 _x_cursor-pointer _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple-100"
          for="uploadImage">
          <span class="_x_font-semibold _x_text-3 _x_text-purple">ფოტოს არჩევა</span>
        </label>
        <ng-template #loader>
          <i class="_x_mb-11" id="countdown">
            <svg class="loader">
              <circle class="loader-circle" cx="14" cy="14" r="12"></circle>
            </svg>
          </i>
        </ng-template>
      </div>
      <button
        (click)="setImageSearchModalState(false)"
        aria-label="clear button"
        class="_x_absolute _x_right-10 _x_top-10 _x_flex _x_items-center _x_justify-center">
        <i class="_x_icon _x_icon-remove _x_cursor-pointer _x_text-4 _x_text-dark"></i>
      </button>
    </div>
  </div>
</ng-container>
